//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      urlData: '',
      visible: false,
      data: {}
    };
  },
  computed: {
    ...mapGetters('user', ['userInfo', 'token'])
  },
  methods: {
    getData() {
      const query = {
        company_name: this.userInfo?.company?.company_name,
        title: this.data?.localized_messages?.title,
        content: this.data?.localized_messages?.content,
        attachment: JSON.stringify(this.data?.attachment || []),
        qrcode: this.data?.qrcode?.link || '',
        lang: this.data?.localized_messages?.lang,
        access_token: this.token
      };
      const queryFormat = Object.keys(query)
        .map(item => query[item] && `${item}=${encodeURIComponent(query[item])}`)
        .join('&');
      this.urlData = `${process.env.VUE_APP_API_BASE_URL}/hub/preview-message?${queryFormat}`;
    },
    showPreview(contentData) {
      this.data = contentData;
      this.visible = true;
      this.getData();
    }
  }
};
