var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 400, title: _vm.$t("Preview"), footer: null },
      model: {
        value: _vm.visible,
        callback: function($$v) {
          _vm.visible = $$v
        },
        expression: "visible"
      }
    },
    [
      _c("div", { staticClass: "container" }, [
        _vm.urlData
          ? _c("iframe", {
              staticClass: "container__img",
              attrs: { src: _vm.urlData }
            })
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }